














































































import moment from 'moment';
import mixins from 'vue-typed-mixins';
import { IClient, IClientStatistic, IClientStatisticsResponse } from '@/interfaces/client';
import { IService } from '@/interfaces/service';
import Base from '@/mixins/Base.vue';
import Colors from '@/mixins/Colors.vue';

const component = mixins(Base, Colors).extend({
    extends: Colors,
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },

    },
    mounted() {
        for (let i = 0; i < 12; i += 1) {
            this.options.xaxis.categories.push(moment().subtract(12 - i, 'month').format('MM/YY'));
            this.options.colors.push(this.adjustBrightness(this.colors[i % this.colors.length], i * 30));
        }
        this.getClientStatistics();
    },
    data() {
        return {
            isAddClientCommentModalActive: false,
            statisticsParams: {
                services: [] as Array<IService>,
            },
            type: 'line',
            statistics: [] as Array<IClientStatistic>,
            options: {
                colors: [],
                xaxis: {
                    categories: [],
                },
            } as any,
            colors: ['#15dba5', '#042f3d'],
        };
    },
    methods: {
        getClientStatistics() {
            this.get<IClientStatisticsResponse>(`clients/${this.$route.params.id}/statistics`, {
                params: {
                    ...this.statisticsParams,
                    services: this.statisticsParams.services.map((p:IService) => p.id),
                },
            }).then(({ data }) => {
                this.statistics = data.data;
            });
        },

    },
});

export default component;
