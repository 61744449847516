



































































































































import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { IService, IServicesResponse } from '@/interfaces/service';
import prices from '@/components/Prices.vue';
import { IPrice } from '@/interfaces/price';

const component = Base.extend({
    components: {
        prices,
    },
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    mounted() {
        this.getPrices();
    },
    data() {
        return {
            activeZoneIndex: 0,
            isActive: undefined,
            services: [] as Array<IService>,
            filteredPrices: [] as Array<IPrice>,
        };
    },
    methods: {
        getPrices(): void {
            const url = `clients/${this.$route.params.id}/services-prices`;

            this.get<IServicesResponse>(url).then(({ data }) => {
                this.services = data.data;
                this.client.prices_count = data.meta.total;
            });
        },
        savePrices(): void {
            const url = `clients/${this.$route.params.id}/services-prices`;

            this.put<IServicesResponse>(url, {
                services: this.services,
            })
                .then(({ data }) => {
                    this.services = data.data;
                    this.$emit('success', { message: 'Prices saved' });
                });
        },

    },
});

export default component;
