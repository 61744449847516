






















































































































































































































































import { IClient } from '@/interfaces/client';
import { IInvoice, IInvoicesResponse } from '@/interfaces/invoice';
import { IPagination } from '@/interfaces/pagination';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    mounted() {
        this.getInvoices();
    },
    data() {
        return {
            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                status: '',
                dates: [],
            },
            invoices: {
                data: [] as Array<IInvoice>,
                meta: {} as IPagination | any,
            } as IInvoicesResponse,
        };
    },

    methods: {
        getInvoices(page: number = 1) {
            this.params.page = page;

            this.get<IInvoicesResponse>(`clients/${this.$route.params.id}/invoices`, {
                params: {
                    ...this.params,
                },
            }).then(({ data }) => {
                this.invoices = data;
            });
        },
    },
});

export default component;
