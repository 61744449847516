






















































































































































import { IClient } from '@/interfaces/client';
import {
    IComment, ICommentResponse, ICommentsResponse,
} from '@/interfaces/comment';
import Base from '@/mixins/Base.vue';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },

    },
    mounted() {
        this.getClientComments();
    },
    data() {
        return {
            apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
            isAddClientCommentModalActive: false,
            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
            },
            comments: {
                data: [] as Array<IComment>,
                meta: {} as IPagination | any,
            } as ICommentsResponse,
            newComment: {} as (any | IComment),
        };
    },
    methods: {
        getClientComments(page: number = 1) {
            this.params.page = page;

            this.get<ICommentsResponse>(`clients/${this.$route.params.id}/comments`, {
                params: this.params,
            }).then(({ data }) => {
                this.comments = data;
            });
        },

        saveClientComment() {
            this.post<ICommentResponse>(`clients/${this.$route.params.id}/comments`, this.newComment).then(() => {
                this.getClientComments();
                this.isAddClientCommentModalActive = false;
            });
        },

        deleteClientComment(comment: IComment) {
            this.confirm().then(() => {
                this.delete(`clients/${this.$route.params.id}/comments/${comment.id}`).then(() => {
                    this.getClientComments();
                });
            });
        },
    },
});

export default component;
