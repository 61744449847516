var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Accounting system setting ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"octopus_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Octopus ID","horizontal":"","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.octopus_id),callback:function ($$v) {_vm.$set(_vm.client, "octopus_id", $$v)},expression:"client.octopus_id"}})],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Factoring setting ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"refused_by_fortis","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Refused by Fortis","horizontal":"","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.refused_by_fortis),callback:function ($$v) {_vm.$set(_vm.client, "refused_by_fortis", $$v)},expression:"client.refused_by_fortis"}})],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Credit setting ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"eulerhermes","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Credit insurer EulerHermes","horizontal":"","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.isLoading},model:{value:(_vm.client.eulerhermes),callback:function ($$v) {_vm.$set(_vm.client, "eulerhermes", $$v)},expression:"client.eulerhermes"}},_vm._l((10),function(i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(" "+_vm._s(i)+" ")])}),0)],1)]}}])}),_c('b-field',{attrs:{"label":"Cash payment required","horizontal":""}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"outstanding_amount_is_greater_than","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"expanded":"","label":"Outstanding amount is greater than","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"expanded":"","type":"number","min":"0","disabled":_vm.isLoading},model:{value:(_vm.client.outstanding_amount_is_greater_than),callback:function ($$v) {_vm.$set(_vm.client, "outstanding_amount_is_greater_than", $$v)},expression:"client.outstanding_amount_is_greater_than"}}),_c('div',{staticClass:"button is-static"},[_vm._v(" € ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"outstanding_amount_for_at_least","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"expanded":"","label":"Outstanding amount is at least","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"expanded":"","type":"number","min":"0","disabled":_vm.isLoading},model:{value:(_vm.client.outstanding_amount_for_at_least),callback:function ($$v) {_vm.$set(_vm.client, "outstanding_amount_for_at_least", $$v)},expression:"client.outstanding_amount_for_at_least"}}),_c('div',{staticClass:"button is-static"},[_vm._v(" Days ")])],1)]}}])})],1),_c('hr'),_c('p',{staticClass:"mb-4"},[_vm._v(" If conditions 1 or 2 above are met: New assignments cannot be scheduled and reports of scheduled assignments will not be emailed until both of the above conditions are cleared. ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" If the Euler 7, 8, 9, 10 and the client has invoices, these are overdue then the client is obliged to pay in cash. ")]),_c('hr'),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"sent_to_euler","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Forwarded to Euler","horizontal":"","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.client.sent_to_euler),callback:function ($$v) {_vm.$set(_vm.client, "sent_to_euler", $$v)},expression:"client.sent_to_euler"}},[_vm._v(" Your client file was forwarded to Euler ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"sent_to_euler_date","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Forwarded at","horizontal":"","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-datepicker',{attrs:{"editable":"","disabled":_vm.isLoading},model:{value:(_vm.client.sent_to_euler_date),callback:function ($$v) {_vm.$set(_vm.client, "sent_to_euler_date", $$v)},expression:"client.sent_to_euler_date"}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }