





import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    mounted() {

    },
    data() {
        return {
        };
    },
    methods: {

    },
});

export default component;
