

















































































































































































































































































import moment from 'moment';
import { IClient } from '@/interfaces/client';
import Base from '@/mixins/Base.vue';

export default Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    data() {
        return {
            days: moment.weekdays(),
            moment,
        };
    },
    watch: {
        active() {

        },
    },
    mounted() {
        this.initOpeningHours();
    },
    methods: {
        initOpeningHours() {
            if (!this.client.opening_hours || this.client.opening_hours.length === 0) {
                this.client.opening_hours = [];
                for (let i = 0; i < 7; i += 1) {
                    this.client.opening_hours.push(
                        {
                            day: i,
                            open: false,
                            hours: [
                                {
                                    from: null,
                                    to: null,
                                },
                            ],
                        },
                    );
                }
            }
        },
        addHourRow(index: number) {
            this.client.opening_hours[index].hours.push({ from: '', to: '' });
        },
        removeHourRow(index: number, index2: number) {
            this.client.opening_hours[index].hours.splice(index2, 1);
        },
    },
});
