















































































































































































































































































































































































































import { IClient } from '@/interfaces/client';
import { IPagination } from '@/interfaces/pagination';
import {
    IService, IServicesResponse,
} from '@/interfaces/service';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    mounted() {
        this.getClientServices();
    },
    data() {
        return {
            isAddClientServiceModalActive: false,
            isCreateAliasModalActive: false,
            services: {
                data: [] as Array<IService>,
                meta: {} as IPagination | any,
            } as IServicesResponse,
            params: {
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            } as any,
            linkedServices: [] as Array<IService>,
            alias: {
                service: undefined as any | IService,
                name: '',
            },
        };
    },
    methods: {

        addClientService(): void {
            this.post(`clients/${this.$route.params.id}/services`, {
                service_ids: this.linkedServices.map((p) => p.id),
            }).then(() => {
                this.getClientServices();
                this.isAddClientServiceModalActive = false;
                this.linkedServices = [];
            });
        },
        addAliasClientService(): void {
            this.post(`clients/${this.$route.params.id}/services`, {
                service_id: this.alias.service ? this.alias.service.id : null,
                name: this.alias.name,
            }).then(() => {
                this.getClientServices();
                this.isCreateAliasModalActive = false;
                this.alias.service = undefined;
                this.alias.name = '';
            });
        },
        getClientServices(page: number = 1): void {
            this.params.page = page;

            this.get<IServicesResponse>(`clients/${this.$route.params.id}/services`, {
                params: this.params,
            }).then(({ data }) => {
                this.services = data;
                this.client.services_count = data.meta.total;
            });
        },
        deleteClientService(service: IService): void {
            this.confirm().then(() => {
                this.delete(`clients/${this.$route.params.id}/services/${service.client_service.id}`).then(() => {
                    this.getClientServices();
                });
            });
        },
    },
});

export default component;
