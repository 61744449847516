var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Operational settings ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"language_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Language","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-language',{model:{value:(_vm.client.language),callback:function ($$v) {_vm.$set(_vm.client, "language", $$v)},expression:"client.language"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"report_language","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Report language","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.client.report_language),callback:function ($$v) {_vm.$set(_vm.client, "report_language", $$v)},expression:"client.report_language"}},[_c('option',{attrs:{"value":"province"}},[_vm._v(" Use language of job province ")]),_c('option',{attrs:{"value":"nl"}},[_vm._v(" Always send report in dutch ")]),_c('option',{attrs:{"value":"fr"}},[_vm._v(" Always send report in french ")]),_c('option',{attrs:{"value":"nl,fr"}},[_vm._v(" Always send report in dutch and french ")])])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"embed_invoice_enabled","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Link invoice in emails","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{model:{value:(_vm.client.embed_invoice_enabled),callback:function ($$v) {_vm.$set(_vm.client, "embed_invoice_enabled", $$v)},expression:"client.embed_invoice_enabled"}},[_vm._v(" Automatically insert link invoice in emails reports ")])],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Renewal ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"renew_services","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Renew services","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{model:{value:(_vm.client.renew_services),callback:function ($$v) {_vm.$set(_vm.client, "renew_services", $$v)},expression:"client.renew_services"}},[_vm._v(" Automatically renew services ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"renew_after_1_day","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Renew after 1 day","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{model:{value:(_vm.client.renew_after_1_day),callback:function ($$v) {_vm.$set(_vm.client, "renew_after_1_day", $$v)},expression:"client.renew_after_1_day"}},[_vm._v(" Automatically renew after 1 day ")])],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"renew_date","tag":"b-field","detect-input":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Renew date until","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-datepicker',{attrs:{"value":_vm.client.renew_date,"editable":"","disabled":_vm.isLoading}})],1)]}}])}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"automatic_inspection","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Automatically inspection","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-checkbox',{model:{value:(_vm.client.automatic_inspection),callback:function ($$v) {_vm.$set(_vm.client, "automatic_inspection", $$v)},expression:"client.automatic_inspection"}},[_vm._v(" Automatic inspection (installation <= 10kVA (service EK6.03) after installation of inverter (services PC.IS.09, PC. IS.20) ")])],1)]}}])})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Opening hours ")]),_c('b-field',{attrs:{"horizontal":"","label":"Opening hours"}},[_c('table',{staticClass:"table is-striped"},[_c('tbody',_vm._l((_vm.client.opening_hours),function(hour,index){return _c('tr',{key:hour.day},[_c('td',[_vm._v(" "+_vm._s(_vm.days[hour.day])+" ")]),_c('td',[_c('b-switch',{attrs:{"type":! hour.open ? 'is-danger' : 'is-success'},model:{value:(hour.open),callback:function ($$v) {_vm.$set(hour, "open", $$v)},expression:"hour.open"}})],1),_c('td',[(hour.open)?_vm._l((hour.hours),function(h,index2){return _c('b-field',{key:index + '-' + index2,staticClass:"mb-2"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":'opening_hours.'+index+'.hours.'+index2+'.from',"tag":"div","detect-input":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]}}},[_c('b-timepicker',{style:({width:60+'px'}),attrs:{"editable":"","value":_vm.moment(h.from,['H:m']).toDate(),"size":"is-small","increment-minutes":15},on:{"input":function($event){h.from = _vm.moment($event).format('HH:mm')}}})],1)]}}],null,true)}),_c('div',{staticClass:"ml-2 mr-2"},[_vm._v(" - ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":'opening_hours.'+index+'.hours.'+index2+'.to',"tag":"div","detect-input":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]}}},[_c('b-timepicker',{style:({width:60+'px'}),attrs:{"editable":"","value":_vm.moment(h.to,['H:m']).toDate(),"size":"is-small","increment-minutes":15},on:{"input":function($event){h.to = _vm.moment($event).format('HH:mm')}}})],1)]}}],null,true)}),(hour.hours.length > 1)?_c('b-button',{attrs:{"type":"is-danger","size":"is-small","inverted":"","icon-left":"trash"},on:{"click":function($event){return _vm.removeHourRow(index,index2)}}}):_vm._e()],1)}):_c('div',{staticClass:"has-text-centered"},[_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v(" closed ")])],1)],2),_c('td',[(hour.open)?_c('b-field',[_c('b-button',{attrs:{"type":"is-primary","outlined":"","size":"is-small","icon-left":"plus"},on:{"click":function($event){return _vm.addHourRow(index)}}})],1):_vm._e()],1)])}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }