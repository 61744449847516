










































































































































































































































import moment from 'moment';
import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { IJob, IJobsResponse } from '@/interfaces/job';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    mounted() {
        this.getClientJobs();
    },
    data() {
        return {
            moment,
            jobs: {
                data: [] as Array<IJob>,
                meta: {} as IPagination | any,
            } as IJobsResponse,

            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'asc',
                page: 1,
                status: '',
            },
        };
    },
    methods: {
        getClientJobs(page: number = 1): void {
            this.params.page = page;

            this.get<IJobsResponse>(`clients/${this.$route.params.id}/jobs`, {
                params: this.params,
            }).then(({ data }) => {
                this.jobs = data;
            });
        },
    },
});

export default component;
