




















































































































































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IUser, IUserResponse, IUsersResponse } from '@/interfaces/user';
import { IClient, IClientResponse } from '@/interfaces/client';
import { IRole } from '@/interfaces/role';
import { IClientUserType } from '@/interfaces/clientusertype';
import { IPagination } from '@/interfaces/pagination';
import { IPermission } from '@/interfaces/permission';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },

    },
    mounted() {
        this.getClientUsers();
    },
    data() {
        return {
            isAddClientUserModalActive: false,
            isCreateUserModalActive: false,
            users: {
                data: [] as Array<IUser>,
                meta: {} as IPagination | any,
            } as IUsersResponse,
            params: {
                name: '',
                sortBy: 'firstname',
                orderBy: 'asc',
                page: 1,
                client_user_type: undefined as any | IClientUserType,
                role: undefined as any | IRole,
            },
            newUser: {
                client_user: {
                    client_user_type: undefined as any | IClientUserType,
                    role: undefined as any | IRole,
                    extra_permissions: [] as Array<IPermission>,
                },
            } as IUser,
            linkUserData: {
                client_user_type: undefined as any | IClientUserType,
                role: undefined as any | IRole,
                extra_permissions: [] as Array<IPermission>,
                user: undefined as any | IUser,
            } as any,
        };
    },
    methods: {
        getClientUsers(page: number = 1): void {
            this.params.page = page;

            this.get<IUsersResponse>(`clients/${this.$route.params.id}/users`, {
                params: {
                    ...this.params,
                    role: this.params.role ? this.params.role.name : null,
                    client_user_type_id: this.params.client_user_type ? this.params.client_user_type.id : null,
                },
            }).then(({ data }) => {
                this.users = data;
                this.client.users_count = data.meta.total;
            });
        },
        addClientUser(): void {
            this.post(`clients/${this.$route.params.id}/users`, {
                role: this.linkUserData.role.name,
                user_id: this.linkUserData.user.id,
                extra_permissions: this.linkUserData.extra_permissions.map((p: IPermission) => p.id),
                client_user_type_id: this.linkUserData.client_user_type.id,
            }).then(() => {
                this.getClientUsers();
                this.isAddClientUserModalActive = false;
            });
        },
        saveClientUser(user: IUser): void {
            if (user.client_user && user.client_user.role && user.client_user.client_user_type) {
                this.put<IClientResponse>(`clients/${this.$route.params.id}/users/${user.id}`, {
                    user_id: user.id,
                    role: user.client_user.role.name,
                    extra_permissions: user.client_user.extra_permissions.map((p: IPermission) => p.id),
                    client_user_type_id: user.client_user.client_user_type.id,
                }).then(() => {
                    this.getClientUsers();
                });
            }
        },
        deleteClientUser(user: IUser): void {
            this.confirm().then(() => {
                this.delete(`clients/${this.$route.params.id}/users/${user.id}`).then(() => {
                    this.getClientUsers();
                });
            });
        },
        createUser() : void{
            const loginUrl: string = `${window.location.protocol}//${window.location.host}${this.$router.resolve({ name: 'auth.login' }).href}`;
            this.post<IUserResponse>('users', {
                username: this.newUser.username,
                firstname: this.newUser.firstname,
                lastname: this.newUser.lastname,
                email: this.newUser.email,
                generic_role: this.newUser.generic_role ? this.newUser.generic_role.name : null,
                client_id: this.client.id,
                client_user: {
                    role: this.newUser.client_user.role.name,
                    extra_permissions: this.newUser.client_user.extra_permissions.map((p: IPermission) => p.id),
                    client_user_type_id: this.newUser.client_user.client_user_type.id,
                },
                login_url: loginUrl,

            }).then(() => {
                this.getClientUsers();
                this.isCreateUserModalActive = false;
            });
        },
    },
});

export default component;
