







































import { IClient } from '@/interfaces/client';
import Base from '@/mixins/Base.vue';
import invoices from './billing/Invoices.vue';
import financial from './billing/Financials.vue';

const component = Base.extend({
    components: {
        invoices,
        financial,
    },
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    data() {
        return {
            activeTab: 'invoices',
        };
    },
});

export default component;
