









































































































































































import { IClient } from '@/interfaces/client';
import { IFinancial, IFinancialsResponse } from '@/interfaces/financial';
import { IPagination } from '@/interfaces/pagination';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    mounted() {
        this.getFinancials();
    },
    data() {
        return {
            activeTab: 'financial',
            financialsParams: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                status: '',
                dates: [],
            },
            financials: {
                data: [] as Array<IFinancial>,
                meta: {} as IPagination | any,
            } as IFinancialsResponse,
        };
    },

    methods: {
        getFinancials() {
            this.get<IFinancialsResponse>(`clients/${this.$route.params.id}/financials`).then(({ data }) => {
                this.financials = data;
            });
        },
    },
});

export default component;
