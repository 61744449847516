
















































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';

import { IClient, IClientResponse } from '@/interfaces/client';
import { ISource } from '@/interfaces/source';
import { IContact } from '@/interfaces/contact';
import users from './partials/Users.vue';
import jobs from './partials/Jobs.vue';
import services from './partials/Services.vue';
import invoices from './partials/Invoices.vue';
import general from './partials/General.vue';
import prices from './partials/Prices.vue';
import quality from './partials/Quality.vue';
import comments from './partials/Comments.vue';
import statistics from './partials/Statistics.vue';

import contacts from './partials/settings/Contacts.vue';
import addresses from './partials/settings/Addresses.vue';
import financialSettings from './partials/settings/FinancialSettings.vue';
import billingSettings from './partials/settings/BillingSettings.vue';
import operationalSettings from './partials/settings/OperationalSettings.vue';
import invoicesAgreement from './partials/settings/InvoicesAgreement.vue';

const component = Base.extend({
    components: {
        statistics,
        contacts,
        addresses,
        invoices,
        users,
        quality,
        jobs,
        prices,
        services,
        comments,
        general,
        financialSettings,
        billingSettings,
        operationalSettings,
        invoicesAgreement,
    },
    data() {
        return {
            activeTab: 0,
            client: undefined as any | IClient,
        };
    },
    mounted() {
        this.getClient();
    },
    methods: {

        getClient(): void {
            this.get<IClientResponse>(`clients/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.client = data.data;
                    this.client.project_po_order_expiry = new Date(this.client.project_po_order_expiry);
                    this.client.renew_date = new Date(this.client.renew_date);
                });
        },
        saveClient(): void {
            this.put<IClientResponse>(`clients/${this.$route.params.id}`, {
                ...this.client,
                flows: [2],
                sources: this.client.sources.map((s: ISource) => s.id),
                language_id: this.client.language ? this.client.language.id : null,
                client_type_id: this.client.client_type ? this.client.client_type.id : null,
                country_id: this.client.country ? this.client.country.id : null,
                region_id: this.client.region ? this.client.region.id : null,
                billing_country_id: this.client.billing_country ? this.client.billing_country.id : null,
                billing_region_id: this.client.billing_region ? this.client.billing_region.id : null,
                parent_client_id: this.client.parent_client ? this.client.parent_client.id : null,
                contacts: this.client.contacts.map((c: IContact) => ({
                    id: c.id,
                    name: c.name,
                    type: c.type,
                    email: c.email,
                    note: c.note,
                    phone: c.phone,
                })),
            })
                .then(({ data }) => {
                    this.client = data.data;
                    this.client.project_po_order_expiry = new Date(this.client.project_po_order_expiry);
                    this.client.renew_date = new Date(this.client.renew_date);

                    this.$emit('success', { message: `Client ${data.data.name} updated` });
                });
        },
    },
});

export default component;
